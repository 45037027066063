export const ANDROID_STORE_URL = 'https://go.onelink.me/app/ecab6946'
export const IOS_STORE_URL = 'https://go.onelink.me/app/88a607f8'
export const CAREERS_URL = '/careers'
export const CONTACT_EMAIL_URL = 'mailto:hi@mixtiles.com'
export const TERMS_OF_SERVICE_URL = '/terms-of-service'
export const ACCESSIBILITY_URL = '/accessibility-statement'
export const PRIVACY_POLICY_URL = '/privacy-notice'
export const JOIN_US_URL = 'https://mixtiles.dev'
export const MIXTILES_PHOTOS_URL = '/photos/home'
export const MIXTILES_ART_URL = '/art'
export const IMPRESSUM_URL = '/impressum'
export const DISCOVERY_URL = '/browse'
export const PHOTO_WALL_URL = '/photo-walls'
export const PHOTO_STYLER_URL = '/photos'
export const PHOTO_STYLER_HOMEPAGE_URL = '/photos/home'
export const CENTERPIECE_SELECTION_URL = '/centerpieces'
export const MY_ORDERS_URL = '/my-orders'
export const ONBOARDING_URL = '/getstarted'
export const ONBOARDING_SMS_URL = `${ONBOARDING_URL}/sms`
export const ONBOARDING_INTRO_URL = `${ONBOARDING_URL}/intro`
export const MINIGAME_URL = '/minigame'
export const GIFT_CARD = '/gift'
export const REFERRAL = '/referral'
export const BUSINESS_PAGE_URL = '/for-business'
export const LOGIN_CALLBACK_URL = '/login-callback'
export const EMBEDDED_LOGIN_URL = '/elogin'
export const INSTAGRAM_URL = 'https://www.instagram.com/mixtiles'
export const INSTAGRAM_ART_URL = 'https://www.instagram.com/mixtiles_art/'
export const FACEBOOK_URL = 'https://www.facebook.com/mixtiles'
export const FACEBOOK_ART_URL = 'https://www.facebook.com/mixtilesart/'
export const TWITTER_URL = 'https://twitter.com/mixtiles'
export const SIZE_SELECTION_URL = '/sizes/select'
export const RECRUITING_COUNTRY = 'IL'
export const POST_PAYMENT_URL = '/post-payment'
export const POST_PAYMENT_QUERY_PATHNAME = 'return_pathname'
export const CANVAS_PRODUCT_PAGE = '/product/canvas'
export const COLLAGE_PRODUCT_PAGE = '/product/collage'
export const JUMBLE_HOME_PAGE = '/jumble/home'
export const JUMBLE_URL_PREFIX = '/jumble'
export const ART_COLLECTION_ENTRYPOINT_URL = '/collection'
export const ART_COLLECTION_HOME_URL = '/collection/home'
export const ART_COLLECTION_DESIGNER_URL = '/collection/design'
export const ART_COLLECTION_BROWSE_URL = '/collection/browse'
export const YOUTUBE_URL = 'https://www.youtube.com/@mixtiles'
export const TIKTOK_URL = 'https://www.tiktok.com/@mixtiles'
export const PINTEREST_URL = 'https://www.pinterest.com/mixtiles/'
export const PHOTO_BOOK_HOMEPAGE_URL = '/photo-books/home'
export const PHOTO_BOOK_URL = '/photo-books'
export const PREFERENCES_SAVED_URL = '/preferences-saved'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/mixtiles'
export const HOMEPAGE_INDEX_URL = '/home'
export const PROD_URL = 'https://mixtiles.com'
export const BLOG_URL = '/blog'
export const PRODUCTS_URL = '/products'
