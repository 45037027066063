import promiseRetry, { type FailedAttemptError } from 'p-retry'
import { logger } from '../services/logger'
import { extractErrorMessage } from './ApiUtils'

export class PromiseTimeoutError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export function timeoutPromise(promise: any, timeoutInMs: number) {
  const timeout = new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(
        new PromiseTimeoutError(
          `Promise took too much time. Timeout reached: ${timeoutInMs}`
        )
      )
    }, timeoutInMs)
  })

  return Promise.race([promise, timeout])
}

type RunAsyncWithRetriesParams<T> = {
  op: (params: any) => Promise<T>
  opName: string
  params?: any
  numRetries?: number
  logMetadata?: object
  logRetryAsError?: boolean
  shouldAbortRetry?: (error: FailedAttemptError) => boolean
  onFailedAttempt?: (error: FailedAttemptError, currParams: any) => any
}
export async function runAsyncWithRetries<T>({
  op,
  opName,
  params = {},
  numRetries = 5,
  logMetadata = {},
  logRetryAsError = false,
  shouldAbortRetry = () => false,
  onFailedAttempt,
}: RunAsyncWithRetriesParams<T>): Promise<T> {
  let currParams = params
  return promiseRetry(async () => op(currParams), {
    retries: numRetries,
    shouldRetry: (e) => !shouldAbortRetry(e),
    onFailedAttempt: async (e: FailedAttemptError) => {
      const logMessage = 'Failed to execute promise, retrying'
      const errorMessage = extractErrorMessage(e)
      const attemptNumber = e.attemptNumber
      const retriesLeft = e.retriesLeft
      const metadata = {
        ...logMetadata,
        opName,
        errorMessage,
        attemptNumber,
        retriesLeft,
      }
      if (logRetryAsError) {
        logger.error(logMessage, e, metadata)
      } else {
        logger.warning(logMessage, metadata)
      }

      if (retriesLeft > 0 && typeof onFailedAttempt === 'function') {
        currParams = await onFailedAttempt(e, currParams)
      }
    },
  })
}
