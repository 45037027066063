import { type UserContextValue } from 'services/UserProvider'

class UserState {
  user?: UserContextValue

  getUser() {
    return this.user
  }

  setUser(user: UserContextValue) {
    this.user = user
  }
}

export const userState = new UserState()
