import * as Sentry from '@sentry/react'
import { createLogglyWriter } from './logglyWriter.client'
import omit from 'lodash/omit'
import { extractErrorMessage } from '../../utils/ApiUtils'
import { isClient } from 'utils/runtimeUtils'

const LogLevels = {
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

const LOG_DEBUG_KEY = 'LOG_DEBUG'

class LoggerService {
  constructor() {
    this.debug = false
    this.didInit = false
    this.writers = []
    this.tags = {}
  }

  init(keys, userId) {
    if (this.didInit) {
      this.warning('Attempted to init logger after it was already initialized')
      return
    }

    this.userId = userId
    this.didInit = true

    if (isClient()) {
      try {
        const allowDebug = localStorage.getItem(LOG_DEBUG_KEY)
        this.debug = allowDebug === 'true'
      } catch (e) {}
      this._initLogglyWriter(keys)
    }

    if (this.debug || keys.runtimeEnv !== 'production') {
      this._initConsoleWriter()
    }

    this.cache.forEach(({ level, message, metadata }) =>
      this._writeLog(level, message, metadata)
    )
    this.cache = []
  }

  _initLogglyWriter(keys) {
    const logglyApiKey = keys.logglyApiKey
    const logglySource = keys.logglySource

    if (logglyApiKey) {
      const logglyWriter = createLogglyWriter(logglyApiKey, logglySource)
      this.writers.push(logglyWriter)
    }
  }

  _initConsoleWriter() {
    this.writers.push(consoleWriter)
  }

  debug(message, metadata = {}) {
    if (this.debug) {
      this._writeLog(LogLevels.DEBUG, message, metadata)
    }
  }

  info(message, metadata = {}) {
    this._writeLog(LogLevels.INFO, message, metadata)
  }

  warning(message, metadata = {}) {
    this._writeLog(LogLevels.WARNING, message, metadata)
  }

  /**
   *
   * @param {string} message
   * @param error
   * @param metadata - Any additional data that we want to get reported alongside our error.
   * @param { Object } metadata.tags - Specific tags that will be reported to sentry for filtering and grouping purposes
   */
  error(message, error, metadata = {}) {
    this._writeLog(LogLevels.ERROR, message, {
      ...metadata,
      error,
      errorMessage: error && extractErrorMessage(error),
    })
    const { tags } = metadata
    // Wrap the original exception with a synthetic exception to allow
    // having the context (message) attached to the error
    const errorOptions = {
      ...(error ? { cause: error } : null),
    }
    const wrappedException = new Error(message, errorOptions)
    Sentry.captureException(wrappedException, {
      extra: omit(metadata, 'tags'),
      tags,
    })
  }

  setTag(key, value) {
    this.tags[key] = value
  }

  cache = []

  _writeLog(level, message, metadata) {
    if (!this.didInit) {
      this.cache.push({ level, message, metadata })
      return
    }

    const params = {
      ...metadata,
      userId: this.userId,
      ...this.tags,
    }

    this.writers.forEach((writer) => {
      writer.write(level, message, params)
    })
  }

  addWriter(writer) {
    this.writers.push(writer)
  }
}

const consoleWriter = {
  write: (level, message, metadata) => {
    switch (level) {
      case LogLevels.DEBUG:
        if (!window.KEYS.silent) console.debug(message, metadata)
        break
      case LogLevels.INFO:
        if (!window.KEYS.silent) console.info(message, metadata)
        break
      case LogLevels.WARNING:
        console.warn(message, metadata)
        break
      case LogLevels.ERROR:
        console.error(message, metadata.error, metadata)
        break
    }
  },
}
export const logger = new LoggerService()
